<template>
  <slot>
    <div class="azgo-haptics-feedback" b="1 solid #00000014" text="#121212 sm" bg="white" px="3" h="9" font="300" flex="~ items-center justify-center" rounded="2" @click.stop="showPopup = true">
      <NuxtIcon mr="1.5" name="my-icon:location" text="sm #121212" />
      Change destination
    </div>
  </slot>

  <BaseAZPopup
    v-model:show="showPopup"
    title="Select destination"
  >
    <div h="12" b="1 solid #00000014" :style="{ borderColor: isFocus ? '#121212' : '#00000014' }" rounded="15" flex="~ items-center shrink-0" mb="4" px="3.5" @click="showPopup = true">
      <NuxtIcon name="my-icon:search" text="xl #121212s" class="align-middle" />
      <div ml="2" flex="1">
        <input v-model="searchInput" class="placeholder:font-300" w="full" text="sm #121212" type="text" :placeholder="t('key.search.result.city.search.placeholder')" @focus="isFocus = true" @blur="isFocus = false" />
      </div>
      <NuxtIcon v-if="searchInput" name="hotel-icon:delete" class="van-haptics-feedback" text="2xl" @click="searchInput = ''" />
    </div>

    <div v-if="!searchInput" mt="4">
      <div text="lg #121212" font="500">
        Taiwan
      </div>

      <div mt="3">
        <van-checkbox-group v-model="checked" direction="horizontal">
          <BaseAZCheckBoxButton
            v-for="item in popularCityList"
            :key="item.cityId" mb="2"
            :label="item.cityName "
            :name="item.cityId"
          >
          </BaseAZCheckBoxButton>
        </van-checkbox-group>

        {{ checked }}

        <!-- <div
          v-for="item in popularCityList" :key="item.cityId" class="azgo-haptics-feedback" mr="2" mb="2" flex="~ items-center justify-center" h="10" rounded="2" px="3" bg="#F6F6F6" text="sm #121212" font="300"
          @click="handleSelectCity(item)"
        >
          {{ item.cityName }}
        </div> -->
      </div>
    </div>

    <template v-else>
      <template v-if="!searchLoading && !resultCityList.length">
        <MobileSearchEmpty :description="t('key.search.result.empy.desc', [searchInput])" />
      </template>
      <template v-else>
        <div flex="1" overflow-y="auto">
          <div v-for="item in resultCityList" :key="item.cityId" flex="~ items-center" px="4" class="van-haptics-feedback" mb="2" @click="handleSelectCity(item)">
            <div flex="shrink-0 ~ items-center justify-center" h="8" w="8" b="1 solid #E4E4E4" rounded="full">
              <NuxtIcon name="hotel-icon:location" text="xl" />
            </div>
            <div ml="2" flex="1" class="van-hairline--bottom" py="4">
              <div text="sm #121212" font="500">
                {{ item.cityName }}
              </div>
              <div text="xs #808080" font="300">
                {{ item.countryName }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </BaseAZPopup>
</template>

<script lang="ts" setup>
import { api as homeService } from '~/apis/back/services/Api'
import type { RegionPopularResponse, RegionSearchResponse } from '~/apis/back/types'

const { t } = useI18n()
const searchInput = ref('')
const isFocus = ref(false)
const { searchParams, showCityPopup: showPopup } = useHotelSearch()
const { data: popularData } = homeService.regionPopularPost({})

const popularCityList = computed(() => {
  return popularData.value?.data || []
})

function handleSelectCity(item: RegionPopularResponse) {
  searchParams.value.selectCity = {
    cityId: `${item.cityId}`,
    cityName: item.cityName || '',
  }
  showPopup.value = false
}

const searchLoading = ref(true)
const resultCityList = ref<RegionSearchResponse[]>([])

watchThrottled(
  searchInput,
  async () => {
    if (searchInput.value.length < 2) {
      return
    }
    searchLoading.value = true
    const { data: resultCityData } = await homeService.regionSearchPost({ keyword: searchInput.value })
    resultCityList.value = resultCityData.value?.data || []

    searchLoading.value = false
  },
  { throttle: 500 },
)

watch(showPopup, (value) => {
  if (value) {
    searchInput.value = ''
    searchLoading.value = true
  }
})

const checked = ref([])
</script>

<style>

</style>
